
.terms-of-use {
	p {
		white-space: pre-line;

		&:not(.fontBold) {
			word-break: break-word;
			hyphens: auto;
		}
	}

	.fontBold {
		font-weight: bold;
	}

	.marginRight10px {
		margin-right: 10px;
	}

	.number-tag {
		margin-right: 10px;
		font-weight: bold;
	}
}
